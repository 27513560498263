import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaRef } from "./captcha.js";
import '../App.css'
import InputForm from "./input.js";
let value;

export function setValue(a) {
    value = a
}
function getClass(msg) {
    let classed;
    switch (msg) {
        case undefined:
            console.log(undefined)
            classed = "btn btn-success btn-lg buttony"
            break
        case 'danger':
            classed = 'btn btn-danger btn-lg buttony'
            break
        case 'dark':
            classed = 'btn btn-dark btn-lg buttony'
            break
        case 'success':
            classed = 'btn btn-success btn-lg buttony'
            break
        case 'warning':
            classed = 'btn btn-warning btn-lg buttony'
            break
        case 'info':
            classed = 'btn btn-info btn-lg buttony'
            break
    }
    return classed;
}
class NiceButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { classes: "btn btn-success btn-lg buttony", message: "Register" };

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({});
    }
    async handleClick() {
        let captcha = recaptchaRef.current.getValue()
        if (captcha === '') {
            // Captcha not Completed
            recaptchaRef.current.reset()
            this.setState({ classes: getClass('warning'), message: "You didn't complete the captcha!" })
        } else {
            this.setState({ classes: getClass('info'), message: "Loading..." })
            let response = await fetch(`${window.location.origin}/api/register?addy=${value}&captcha=${captcha}`).then(res => res.json())

            switch (response) {
                case 1:
                    recaptchaRef.current.reset()
                    this.setState({ classes: getClass('success'), message: "Success!" })
                    break
                case 2:
                    recaptchaRef.current.reset()
                    this.setState({ classes: getClass('danger'), message: "This address does not contain any VITC" })
                    break
                case 3:
                    recaptchaRef.current.reset()
                    this.setState({ classes: getClass('dark'), message: "You did not complete the captcha" })
                    break
                case 4:
                    recaptchaRef.current.reset()
                    this.setState({ classes: getClass('warning'), message: "This Address is already registered." })
                    break
                case 0:
                    recaptchaRef.current.reset()
                    this.setState({ classes: getClass('danger'), message: "The string you provided is not a VITE address." })
                    break
                case 429:
                    recaptchaRef.current.reset()
                    this.setState({ classes: getClass('danger'), message: "Sorry, your address is blacklisted, seek help at our discord." })
                    break
            }



        }
    }
    render() {


        return (
            <button type="button" className={this.state.classes} onClick={this.handleClick}>{this.state.message}</button>
        )

    }
}

export default NiceButton