import logo from './logo.svg';
import './App.css';
import CaptchaBox from './partials/captcha.js';
import FontImports from './partials/fonts.js'
import NiceButton from './partials/button.js'
import InputForm from './partials/input.js'
import React from 'react';
const recaptchaRef = React.createRef()

function App() {
  return (
    // Import Fonts
<>
    <FontImports/>

    <div className="App">
        
      <header className="App-header">
        <img src="https://assets.coingecko.com/coins/images/20661/large/vitc_200.png?1637545079" className="App-logo" alt="logo" />
        <p className="Headertext">
          VitaminDAO Register
        </p>
   
        <p className="BelowText">
          Enter your Vite address to register.
        </p>

        <InputForm></InputForm>
<CaptchaBox></CaptchaBox>


 
<NiceButton/>
      </header>
    </div>
    </>  
  );
}

export default App;
