import React from 'react'
import Helmet from 'react-helmet'
import {setValue} from './button.js'
class InputForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
 
      }
      handleChange(event) {
        setValue(event.target.value)
        this.setState({value: event.target.value});
      }
    render() {
        return (
          <div className='addressdiv'>
            <input type="text" className="form-address form-control " placeholder='vite_' onChange={this.handleChange}/>
            </div>
        )
        }
        }
export default InputForm