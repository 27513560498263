
import ReCAPTCHA from 'react-google-recaptcha'
import React from 'react'
export const recaptchaRef = React.createRef()
class CaptchaBox extends React.Component {
    onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.props.onSubmit(recaptchaValue);
      }

    render() {
        return (
            <form onSubmit={this.onSubmit} >
            <ReCAPTCHA
            className='g-recaptcha'
              ref={recaptchaRef}
              sitekey="6LdghsscAAAAAIsREpyvw5e9TWd48wF-72hEP1mS"
            />
          </form>
        )
    }
}

export default CaptchaBox
